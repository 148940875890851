import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {UtilsService} from './utils.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Log} from '../interfaces/log';
import * as moment from 'moment';
import {map} from 'rxjs/operators';

@Injectable()
export class LogService {

  constructor(public http: HttpClient, public utils: UtilsService) {
  }

  getLog(): Observable<HttpResponse<Log[]>> {
    return this.http.get(this.utils.getServerUrl() + '/easywait/log/',
      {observe: 'response'})
      .pipe(map((response: HttpResponse<Log[]>) => {
        const rsp = JSON.parse(JSON.stringify(response));
        rsp.body = response.body.map((log) => {
          // log.isWL = true;
          log.isWLReservation = log.isWL && log.dateFrom ? true : false;
          return log;
        });
        return rsp;
      })) as Observable<HttpResponse<Log[]>>;
  };

  removeReservation(reservationId): Observable<any> {
    return this.http.delete(this.utils.getServerUrl() + '/reservations/' + reservationId,
      {}) as Observable<any>;
  };

}
