import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { Observable } from "rxjs/Observable";
import { User } from "../interfaces/user";
import { ConnectedUser } from "../interfaces/connected-user";
import "rxjs/add/operator/publishReplay";
import { Subject } from "rxjs/Subject";
import * as _ from "lodash";
import { of, ReplaySubject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import * as moment from "moment";

@Injectable()
export class AccountService {
  private connectedAccounts: Observable<ConnectedUser[]> = null;
  private user: User = null;

  private user$: ReplaySubject<User> = new ReplaySubject(1);
  private freshUser$: Subject<User> = new Subject();

  public verificationModal: Subject<boolean> = new Subject();

  constructor(
    public http: HttpClient,
    public utils: UtilsService,
    public storage: StorageService
  ) {}

  getUser(username?: string, force: boolean = false): Observable<User> {
    this.loadUser(username, force);
    return this.user$;
  }

  getFreshUser(username?: string, force: boolean = false): Observable<User> {
    this.loadUser(username, force);
    return this.freshUser$;
  }

  loadUser(
    username?: string,
    force: boolean = false,
    isInitial: boolean = false
  ) {
    if (isInitial) this.user$ = new ReplaySubject(1);

    if (!username) {
      const user = this.storage.getUserData();
      if (user["email"]) username = "?email=" + encodeURIComponent(user.email);
      else if (user["id"]) username = "?id=" + user["id"];
    } else username = "?email=" + encodeURIComponent(username);

    if (!this.user || force) {
      this.http
        .get(this.utils.getPublicServerUrl() + "/user/" + username)
        .pipe(
          map((user: User) => {
            if (user)
              user.birthDay = moment(user.birthDay)
                .add(12, "hours")
                .toISOString();
            return user;
          })
        )
        .subscribe((user: User) => {
          if (!this.user && !this.storage.getMainUser()) {
            let mainToken = JSON.parse(
              JSON.stringify(this.storage.getTokenData())
            );
            this.storage.setMainUser(mainToken);
          }

          this.user$.next(user);
          this.freshUser$.next(user);
          this.user = user;
          this.storage.setUserFullData(user);
        });
    }
  }

  //Users who can manage you

  getManagedUsers(user_id: number): Observable<object> {
    return this.http.get(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managed"
    );
  }

  postManagedUser(user_id: number, email): Observable<object> {
    let body = { email: email };
    return this.http.post(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managed",
      body
    );
  }

  putManagedUser(user_id: number, user): Observable<object> {
    let body = {
      userId: user.userId,
      email: user.email,
      allowStatus: user.allowedStatus,
    };
    return this.http.put(
      this.utils.getRelativesApiPath() +
        "/user/" +
        this.storage.getUserData().id +
        "/managed",
      body
    );
  }

  deleteManagedUser(user_id, user): Observable<object> {
    const options = {
      headers: {},
      body: {
        userId: user.userId,
        email: user.email,
        allowStatus: user.allowedStatus,
      },
    };
    return this.http.delete(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managed",
      options
    );
  }

  //Users whom you can manage

  getManagingUsers(user_id: number): Observable<object> {
    return this.http.get(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managing"
    );
  }

  postManagingUser(user_id: number, user): Observable<object> {
    let body = { kzz: user.kzz, searchData: user.fullName };
    return this.http.post(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managing",
      body
    );
  }

  putManagingUser(user_id: number, user): Observable<object> {
    let body = {
      userId: user.userId,
      email: user.email,
      allowStatus: user.allowedStatus,
    };
    return this.http.put(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managing",
      body
    );
  }

  deleteManagingUser(user_id, user): Observable<object> {
    const options = {
      headers: {},
      body: {
        userId: user.userId,
        email: user.email,
        allowStatus: user.allowedStatus,
      },
    };
    return this.http.delete(
      this.utils.getRelativesApiPath() + "/user/" + user_id + "/managing",
      options
    );
  }

  userExists(kzz) {
    return this.http.get(
      this.utils.getPublicServerUrl() + "/registeredInfo?kzz=" + kzz
    );
  }

  postMakeIndependantUser(userId: number, user) {
    return this.http.post(
      this.utils.getPublicServerUrl() + "/changeManagedUserToFull/" + userId,
      user
    );
  }

  updateUser(username: string, userData: User): Observable<object> {
    this.user = null;
    return this.http
      .put(
        this.utils.getPublicServerUrl() +
          "/user/" +
          encodeURIComponent(username) +
          "/",
        userData
      )
      .pipe(
        map((el) => {
          this.loadUser(username, true);
          return el;
        })
      );
  }

  updateEmail(username: string, userData) {
    return this.http
      .post(
        this.utils.getPublicServerUrl() + "/users/" + username + "/change",
        userData
      )
      .pipe(
        map((el) => {
          this.loadUser(username, true);
          return el;
        })
      );
  }

  connectedByMail() {
    return this.http.get(
      this.utils.getPublicServerUrl() + "/user/connectedByMail"
    );
  }

  getInstitutionSettings(orgId, setting?) {
    let setting_code = setting ? "/setting/" + setting : "";
    return this.http.get(
      this.utils.getSettingsServerUrl() + "/institution/" + orgId + setting_code
    );
  }

  getUserSettings(userId: number, setting?) {
    let setting_code = setting ? "/setting/" + setting : "";
    return this.http.get(
      this.utils.getSettingsServerUrl() + "/user/" + userId + setting_code
    );
  }

  postUserSettings(userId: number, setting) {
    return this.http.post(
      this.utils.getSettingsServerUrl() +
        "/user/" +
        userId +
        "/setting/" +
        setting.settingKey,
      setting
    );
  }

  resetPassword(username: string, newPassword: string): Observable<object> {
    this.user = null;
    this.loadUser(username, true);
    return this.http.put(
      this.utils.getPublicServerUrl() +
        "/password/" +
        encodeURIComponent(username) +
        "/",
      newPassword
    );
  }

  getConnectedAccounts(): Observable<ConnectedUser[]> {
    return of([]);
    if (!this.connectedAccounts) {
      this.connectedAccounts = this.http
        .get(this.utils.getServerUrl() + "/relatives/")
        .publishReplay(1)
        .refCount() as Observable<ConnectedUser[]>;
    }
    return this.connectedAccounts;
  }

  addConnectedAccount(userData: ConnectedUser): Observable<object> {
    this.connectedAccounts = null;
    return this.http.post(this.utils.getServerUrl() + "/relatives", userData);
  }

  deleteConnectedAccount(kzz: number): Observable<object> {
    this.connectedAccounts = null;
    return this.http.delete(this.utils.getServerUrl() + "/relatives/" + kzz);
  }

  getUserByKzz(kzz: number): Observable<User | ConnectedUser> {
    const observable = new Subject<User | ConnectedUser>();

    setTimeout(() => {
      this.getUser().subscribe((user: User) => {
        if (user.kzz === kzz) {
          observable.next(user);
        } else {
          this.getConnectedAccounts().subscribe((users: ConnectedUser[]) => {
            const foundUser = _.find(users, function (connectedUser) {
              return connectedUser.kzz === kzz;
            });
            observable.next(foundUser);
          });
        }
      });
    }, 50);

    return observable.asObservable();
  }

  deleteAccount(email: string, comment: string): Observable<object> {
    return this.http.post(
      this.utils.getPublicServerUrl() + "/users/" + email + "/delete",
      { comment: comment }
    );
  }

  sendVerificationCode(username: string): Observable<any> {
    return this.http
      .post(
        this.utils.getPublicServerUrl() + "/patientSendCode/" + username + "/",
        {}
      )
      .pipe(
        map((el) => {
          this.loadUser(username, true);
          return el;
        })
      );
  }

  verifyAccountCode(username: string, code: string): Observable<any> {
    return this.http
      .post(
        this.utils.getPublicServerUrl() +
          "/enterConfirmCode/" +
          username +
          "/" +
          code +
          "/",
        {}
      )
      .pipe(
        map((el) => {
          this.loadUser(username, true);
          return el;
        })
      );
  }

  showVerificationModal() {
    this.verificationModal.next(true);
  }

  checkTerms() {
    return this.http.get(environment.eulaTimeStamp).pipe(
      catchError(() => {
        return of({
          "last-terms-update": "2000-01-01T00:00:00.000Z",
        });
      })
    );
  }

  getUserConnections(username: string) {
    return this.http.get(
      this.utils.getPublicServerUrl() + "/users/" + username + "/guids"
    );
  }

  deleteUserConnection(username: string, guid: string) {
    return this.http.delete(
      this.utils.getPublicServerUrl() + "/users/" + username + "/guids/" + guid
    );
  }
}
