import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from './utils.service';
import {StorageService} from './storage.service';
import {Doctor} from '../interfaces/doctor';
import {catchError} from 'rxjs/operators';

@Injectable()
export class PrescriptionsService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService,
    private storage: StorageService,
  ) {
  }

  getPrescriptionsLog(): Observable<any> {
    const userData = this.storage.getUserData();
    return this.http.get(this.utils.getPrescriptionsUrl() + '/getPrescriptions/' + userData.kzz).pipe(
      catchError(() => {
        return of({prescriptions: []});
      })
    ) as Observable<any>;
  }

  createPrescriptionOrder(params: any, doctor: Doctor, location: any, userLocalId: number,
                          comment: string): Observable<number> {
    //console.log(params);
    const payload = {
      user: {...{localId: userLocalId}, ...this.storage.getUserFullData()},
      medications: params,
      comment: comment,
      doctor: {
        doctor: doctor.id,
        doctorName: doctor.desc,
        institution: location.institutionId,
        institutionName: location.institutionName,
      }
    };

    return this.http.post(this.utils.getPrescriptionsUrl() + '/createPrescription',
      {
        commandHeader: {
          commandId: '',
          commandName: 'createPrescription',
          sessionId: this.storage.getUserGUID(),
          destinations: [location.institutionId],
          user: this.storage.getUserFullData(),
          cmdTime: Date.now(),
        },
        payload: JSON.stringify(payload)
      }, {}) as Observable<number>;
  };

  cancelPrescription(id: any, comment: string): Observable<number> {
    return this.http.post(this.utils.getPrescriptionsUrl() + '/cancelPrescription/' + id,
      {
        prescriptionId: id,
        comment: comment
      }, {}) as Observable<number>;
  };

  sendAttachment(prescriptionId: number, filename: string, base64: string, desc: string = null): Observable<any> {
    const token = this.storage.getTokenData();

    if (!desc) {
      desc = filename;
    }

    const body = new URLSearchParams();
    body.set('metadataDesc', desc);
    body.set('metadataFilename', filename);
    body.set('attachmentBase64Data', base64);

    return this.http.post(this.utils.getPrescriptionsUrl() + `/prescription/${prescriptionId}/attachment`, body.toString(), {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }) as any;
  }

  postAttachmentGuid(attachmentId: string) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.post(this.utils.getPrescriptionsUrl() + `/patient/${userData.kzz}/attachment/${attachmentId}/guid`, {}, {
      headers: {Authorization: 'Bearer ' + token.accessToken}
    }) as any;
  }

  getAttachmentContent(guid) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.get(`${this.utils.getPrescriptionsUrl()}/patient/${userData.kzz}/attachment/guid/${guid}/content`, {
      headers: {Authorization: 'Bearer ' + token.accessToken},
      responseType: 'blob',
      //observe: 'response'
    });
  }
}
