import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {UtilsService} from './utils.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Log} from '../interfaces/log';
import {StorageService} from './storage.service';
import * as moment from 'moment';
import {MomentInput} from 'moment';
import {Doctor} from '../interfaces/doctor';
import {Location} from '../interfaces/location';
import {of} from 'rxjs';
import {Referral} from '../interfaces/referral';
import {SickList} from '../interfaces/sick-list';

@Injectable()
export class SickListsService {

  constructor(public http: HttpClient, public utils: UtilsService, private storage: StorageService) {
  }

  getSickLists(dateFrom: moment.Moment = moment('2020-01-01'),
               state: number = null
  ): Observable<HttpResponse<SickList[]>> {
    const userData = this.storage.getUserData();
    return this.http.get(this.utils.getPrescriptionsUrl() + '/getSicklists/' + userData.kzz,
      {
        observe: 'response',
        params: {
          // state: state.toString(),
          dateFrom: dateFrom.format('YYYY-MM-DD')
        }
      }) as Observable<HttpResponse<SickList[]>>;
  };

  cancelSickList(sickListId, comment): Observable<any> {
    return this.http.post(this.utils.getPrescriptionsUrl() + '/cancelSicklist/' + sickListId,
      comment) as Observable<any>;
  }

  /*
  removeSickList(referralId): Observable<any> {
    return this.http.post(this.utils.getPrescriptionsUrl() + '/closeReferral/' + referralId,
      {}) as Observable<any>;
  };
  */

  confirmSickList(doctor: Doctor, location: Location, comment: string = '') {
    const userData = this.storage.getUserData();
    return this.http.post(this.utils.getPrescriptionsUrl() + '/createSickList',
      {
        patientKzz: userData.kzz,
        institution: location.id,
        institutionName: location.desc,
        doctor: doctor.id,
        doctorName: doctor.desc,
        commentPatient: comment,
      },
      {
        observe: 'response',
      }) as Observable<HttpResponse<SickList[]>>;
  }

  sendAttachment(sicklistId: number, filename: string, base64: string, desc: string = null): Observable<any> {
    const token = this.storage.getTokenData();

    if (!desc) {
      desc = filename;
    }

    const body = new URLSearchParams();
    body.set('metadataDesc', desc);
    body.set('metadataFilename', filename);
    body.set('attachmentBase64Data', base64);

    return this.http.post(this.utils.getPrescriptionsUrl() + `/sicklist/${sicklistId}/attachment`, body.toString(), {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }) as any;
  }

  postAttachmentGuid(attachmentId: string) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.post(this.utils.getPrescriptionsUrl() + `/patient/${userData.kzz}/attachment/${attachmentId}/guid`, {}, {
      headers: {Authorization: 'Bearer ' + token.accessToken}
    }) as any;
  }

  getAttachmentContent(guid) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.get(`${this.utils.getPrescriptionsUrl()}/patient/${userData.kzz}/attachment/guid/${guid}/content`, {
      headers: {Authorization: 'Bearer ' + token.accessToken},
      responseType: 'blob',
      //observe: 'response'
    });
  }
}
