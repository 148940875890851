import { Injectable, EventEmitter } from "@angular/core";

import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";

import * as _ from "lodash";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class UtilsService {
  public reloadData: EventEmitter<any> = new EventEmitter();
  public openUserHeader: boolean = false;
  public newUser;
  private alphabet = [
    "A",
    "B",
    "C",
    "Ć",
    "Č",
    "D",
    "Đ",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "Š",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "Ž",
  ];

  constructor(public jwtHelper: JwtHelperService, public http: HttpClient) {}

  generateGuid(): string {
    function r4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      r4() +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      r4() +
      r4()
    );
  }

  toCamelCaseObject(rawObject: object): object {
    const newObject = {};

    _.forIn(rawObject, function (value, key) {
      const camelCaseKey = _.camelCase(key);
      newObject[camelCaseKey] = value;
    });

    return newObject;
  }

  parseUserData(token: { [s: string]: string }): object {
    const rawUserData = this.jwtHelper.decodeToken(token.accessToken);

    return this.toCamelCaseObject(rawUserData);
  }

  parseFullName(fullName: string): { firstName: string; lastName: string } {
    const fullNameSplitter = _.trim(fullName).split(" "),
      parsedName = {
        firstName: "",
        lastName: "",
      };

    if (fullNameSplitter.length > 1) {
      parsedName.firstName = _.first(fullNameSplitter);
      parsedName.lastName = _.trim(fullName.split(parsedName.firstName)[1]);
    } else if (fullNameSplitter.length === 1) {
      parsedName.firstName = _.first(fullNameSplitter);
    }

    return parsedName;
  }

  parseInitials(fullName: string): string {
    const splitted = this.parseFullName(fullName);
    return splitted.firstName.slice(0, 1) + splitted.lastName.slice(0, 1);
  }

  CharCompare(a, b, index) {
    if (a != undefined && b != undefined) {
      if (index == a.length || index == b.length) return 0;

      var aChar = this.alphabet.indexOf(a.toUpperCase().charAt(index));
      var bChar = this.alphabet.indexOf(b.toUpperCase().charAt(index));
      if (aChar != bChar) return aChar - bChar;
      else return this.CharCompare(a, b, index + 1);
    }
  }

  userListCleaner(tokenData) {
    if (tokenData) {
      let usersAllowed = tokenData["usersAllowed"];
      if (usersAllowed) {
        //usersAllowed = usersAllowed.filter(managingUser => managingUser.enabled);

        usersAllowed.sort((a, b) => {
          if (a.name !== undefined && b.name !== undefined) {
            return this.CharCompare(a.name, b.name, 0);
          }
        });

        tokenData["usersAllowed"] = usersAllowed;
      }
      return tokenData;
    }
    return "";
  }

  // isActiveValidity(data: any): boolean {
  //   if (!data || !data.validity) return true;

  //   let result: boolean = true;
  //   let schedule = data.validity.schedule;
  //   let today = new Date().getDay();

  //   if (schedule.active) {
  //     switch (today) {
  //       case 0:
  //         result = this._isActiveDay(schedule.sunday);
  //         break;
  //       case 1:
  //         result = this._isActiveDay(schedule.monday);
  //         break;
  //       case 2:
  //         result = this._isActiveDay(schedule.tuesday);
  //         break;
  //       case 3:
  //         result = this._isActiveDay(schedule.wednesday);
  //         break;
  //       case 4:
  //         result = this._isActiveDay(schedule.thursday);
  //         break;
  //       case 5:
  //         result = this._isActiveDay(schedule.friday);
  //         break;
  //       case 6:
  //         result = this._isActiveDay(schedule.saturday);
  //         break;
  //     }
  //   }

  //   if (data.validity.datetime && data.validity.datetime.status != null) {
  //     if (this.isDatetimeValid(data)) {
  //       if (data.validity.datetime.status == 1) {
  //         result = true;
  //       } else {
  //         result = false;
  //       }
  //     }
  //   }

  //   return result;
  // }

  // isDatetimeValid(data: any): boolean {
  //   let valid = { from: undefined, to: undefined };

  //   if (data.validity.datetime.from && data.validity.datetime.from.date) {
  //     valid.from = new Date(data.validity.datetime.from.date);
  //     if (data.validity.datetime.from.time) {
  //       let time = data.validity.datetime.from.time.split(":");
  //       valid.from.setHours(time[0]);
  //       valid.from.setMinutes(time[1]);
  //     }
  //   }

  //   if (data.validity.datetime.to && data.validity.datetime.to.date) {
  //     valid.to = new Date(data.validity.datetime.to.date);
  //     if (data.validity.datetime.to.time) {
  //       let time = data.validity.datetime.to.time.split(":");
  //       valid.to.setHours(time[0]);
  //       valid.to.setMinutes(time[1]);
  //     }
  //   }

  //   return (
  //     (valid.from ? valid.from < new Date() : false) &&
  //     (valid.to ? valid.to > new Date() : false)
  //   );
  // }

  // private _isActiveDay(day): boolean {
  //   switch (day.option) {
  //     case 1:
  //       return true;
  //     case 0:
  //       let date = new Date();

  //       let validity = { from: new Date(), to: new Date() };
  //       let time_from = day.from.split(":");
  //       validity.from.setHours(time_from[0]);
  //       validity.from.setMinutes(time_from[1]);

  //       let time_to = day.to.split(":");
  //       validity.to.setHours(time_to[0]);
  //       validity.to.setMinutes(time_to[1]);

  //       return (
  //         validity.from.getTime() < date.getTime() &&
  //         validity.to.getTime() > date.getTime()
  //       );
  //     case -1:
  //       return false;
  //   }
  // }

  // isActiveModule(data: any, module: string): boolean {
  //   if (!data) return true;

  //   if (data.external) {
  //     let disabled = data.external.modules[module];
  //     let validity = data.external.validity;

  //     let start = new Date(validity.from.date);
  //     start.setHours(validity.from.time.split(":")[0]);
  //     start.setMinutes(validity.from.time.split(":")[0]);

  //     let end = new Date(validity.to.date);
  //     end.setHours(validity.to.time.split(":")[0]);
  //     end.setMinutes(validity.to.time.split(":")[0]);

  //     if (start < new Date() && end > new Date()) {
  //       return !disabled;
  //     }
  //   }

  //   if (data.modules) {
  //     let validity = data.modules[module].validity;

  //     let default_state = this.isActiveValidity(data.modules["default"]);

  //     if (validity.schedule.active)
  //       default_state = this.isActiveValidity(data.modules[module]);

  //     if (validity.datetime && validity.datetime.status != null) {
  //       if (this.isDatetimeValid(data.modules[module])) {
  //         if (validity.datetime.status == 1) {
  //           default_state = true;
  //         } else {
  //           default_state = false;
  //         }
  //       }
  //     }

  //     return default_state;
  //   } else return true;
  // }

  getModuleNotification(data: any, module: string): string {
    let notification = "";
    if (!data) return "";

    if (data.modules) {
      let status =
        data.modules_validity &&
        data.modules_validity[module] &&
        data.modules_validity[module].active
          ? "enabled"
          : "disabled";

      if (module != "default")
        notification = this.getModuleNotification(data, "default");

      if (
        data.modules[module].notification &&
        data.modules[module].notification[status] &&
        data.modules[module].notification[status].text
      ) {
        notification = data.modules[module].notification[status].text;
      }

      let validity = data.modules[module].validity;
      if (validity.datetime && validity.datetime.status != null) {
        let datetime_validity =
          data.modules_validity && data.modules_validity[module].datetime_valid;
        notification = datetime_validity
          ? validity.datetime.text
            ? validity.datetime.text
            : notification
          : notification;
      }
    }

    if (data.external) {
      let ext_start = new Date(
        data.external.validity.from.date +
          "T" +
          data.external.validity.from.time
      );
      let ext_end = new Date(
        data.external.validity.to.date + "T" + data.external.validity.to.time
      );
      let now = new Date();
      console.log(ext_start < now && now < ext_end);
      if (
        ext_start < now &&
        now < ext_end &&
        !(
          data.modules_validity &&
          data.modules_validity[module] &&
          data.modules_validity[module].active
        ) &&
        data.external.notification
      )
        notification = data.external.notification;
    }

    return notification;
  }

  getJSONValidation(json: object) {
    let formData = new FormData();
    formData.append("json", JSON.stringify(json));

    return this.http.post(
      "https://app.infonet.si/x21/services/validity/json/",
      formData
    );
  }

  sanitizeFilename(filename) {
    return filename.replace(/\x00-\x1F/g, "").replace(/[<>:"\/\\|?*]/g, "-");
  }

  getServerUrl(): string {
    return (
      environment.apiServer + ":" + environment.apiPort + environment.apiPath
    );
  }

  getDocumentsUrl(): string {
    return (
      environment.documentsServer +
      ":" +
      environment.documentsPort +
      environment.documentsPath
    );
  }

  getAuthUrl(): string {
    return (
      environment.authServer +
      ":" +
      environment.authApiPort +
      environment.authApiPath
    );
  }

  getPublicServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.publicApiPath
    );
  }

  getModulesServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.modulesApiPath
    );
  }

  getSettingsServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.settingsApiPath
    );
  }

  getSocketUrl(): string {
    return (
      environment.apiServer + ":" + environment.apiPort + environment.socketPath
    );
  }

  getTvwUrl(): string {
    return environment.tvwUrl;
  }

  getPrescriptionsUrl(): string {
    return (
      environment.prescriptionsServer +
      ":" +
      environment.prescriptionsPort +
      environment.prescriptionsPath
    );
  }

  getMessagesUrl(): string {
    return (
      environment.messagesServer +
      ":" +
      environment.messagesPort +
      environment.messagesPath
    );
  }

  getRelativesApiPath(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.relativesApiPath
    );
  }

  getStaticConfig(): Observable<any> {
    return this.http.get(environment.staticConfig).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }
}
