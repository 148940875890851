import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/throw';
import {Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster';
import {LoginService} from './modules/shared/login/login.service';
import {environment} from '../environments/environment';
import {Subject} from 'rxjs/Subject';
import {catchError, switchMap} from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();


  constructor(private router: Router,
              private toaster: ToasterService,
              private loginService: LoginService) {
  }


  addAuthHeader(request, accessToken) {
    if (accessToken) {
      return request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
      });
    }
    return request;
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe((token) => {
          observer.next(token);
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.loginService.refreshToken()
        .do((token) => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(token);
        })
        .catch((error) => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.thrownError(error);
          return error;
        });
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err) => {

      /*
       * Unauthorized handler (includes expired tokens)
       */
      if (err instanceof HttpErrorResponse && err.status === 401) {

        if (err.url.indexOf(environment.authApiPath) !== -1) {
          return Observable.throwError(err);
        }

        return this.refreshToken()
          .pipe(switchMap(
            (res) => {
              if (res) {
                req = this.addAuthHeader(req, res);
                return next.handle(req);
              } else {
                return Observable.empty();
              }
            }
          ))
          .catch(() => {
            this.loginService.logout();
            this.router.navigate(['/']);

             /*this.toaster.pop({
             type: 'error',
             title: 'Seja je potekla', // @TODO Move to translations
              // @TODO Move to translations
             body: 'Vaša seja je potekla. Ponovno se prijavite.'
           }); */

           swal({
            title: 'Seja je potekla',
            html: `Vaša seja je potekla. Ponovno se prijavite.`,
            type: 'error',
            confirmButtonText: 'Zapri',
            confirmButtonClass: 'btn btn-info btn-pill',
            showCloseButton: true
          });

            return Observable.empty();
          });
      }

      if (err instanceof HttpErrorResponse && err.status === 403) {
        this.toaster.pop({
          type: 'error',
          title: 'Forbidden', // @TODO Move to translations
          body: '' // @TODO Move to translations
        });
      }

      return Observable.throwError(err);
    }));
  }
}
