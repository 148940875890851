import {Injectable} from '@angular/core';

import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {StorageService} from '../../../shared-services/storage.service';
import {UtilsService} from '../../../shared-services/utils.service';

import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
import {HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs/Observable';

import {NewUser} from '../../../interfaces/new-user';
import {CustomEncoder} from '../../../shared-services/custom-encoder';
import {SocketService} from '../../../shared-services/socket.service';
import {AccountService} from '../../../shared-services/account.service';
import {map} from 'rxjs/operators';

@Injectable()
export class LoginService {

  constructor(public http: HttpClient, public storage: StorageService, public utils: UtilsService,
              public jwtHelper: JwtHelperService, private socket: SocketService, private account: AccountService) {
  }

  login(username: string, password: string): Observable<boolean | object> {

    const auth = 'Basic ' + btoa(environment.authUsername + ':' + environment.authPassword);
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
      .set('authorization', auth);

    const params = new HttpParams({encoder: new CustomEncoder()})
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    return this.http.post(this.utils.getAuthUrl(), params.toString(), {headers: headers, observe: 'response'})
      .map((response: any) => {

        let tokenRawData, tokenData;
        if (response.body.kzz === '') {
          response.body.kzz = null;
        }

        if (!response || response.status !== 200) {
          return false;
        }

        this.storage.setUserGUID();
        tokenRawData = response.body;
        tokenData = this.storage.setTokenData(tokenRawData);
        this.storage.setMainUser(tokenData);
        this.storage.setUserData(tokenData);
        this.account.loadUser(null, true, true);

        // if (!_.isEmpty(this.storage.getUserData())) {
        //   // $rootScope.app.userInfo = cmnStorageSvc.getUserData();
        // }

        this.socket.initSocket();

        return response.body;

        // return this.setAuthorizationHeader(tokenData.accessToken);
      });
    /*
     return $q(function (resolve, reject) {


     cmnHttpSvc.get('', oAuthData, config)
     .then(function (response) {
     var tokenRawData,
     tokenData;
     if (_.isEmpty(response) || response.status !== 200) {
     resolve(false);

     return null;
     }

     cmnStorageSvc.setUserGUID();
     tokenRawData = response.data;
     tokenData = cmnStorageSvc.setTokenData(tokenRawData);
     cmnStorageSvc.setUserData(tokenData);
     if (!_.isEmpty(cmnStorageSvc.getUserData())) {
     $rootScope.app.userInfo = cmnStorageSvc.getUserData();
     }

     return svc.setAuthorizationHeader(tokenData.accessToken);
     }, function (response) {
     return svc.setAuthorizationHeader('', response);
     })
     .then(function () {
     cmnSocketSvc.init();
     resolve(true);
     }, function (response) {
     reject(response);
     });
     });*/
  }

  pairUser(pairingGuid, user){
    return this.http.patch(this.utils.getPublicServerUrl() + '/users/'  + user.id + '/guids/' + pairingGuid, undefined);
  }

  register(userData: NewUser, pathParams: string): Observable<object> {
    if (userData.kzz === '') {
      userData.kzz = null;
    }
    return this.http.post(this.utils.getPublicServerUrl() + '/register' + pathParams, userData);
  };

  registerNewManaged(userData: NewUser): Observable<object> {
    if (userData.kzz === '') {
      userData.kzz = null;
    }
    return this.http.post(this.utils.getPublicServerUrl() + '/registerNewManagedUser', userData);
  };

  clearSessionData(): void {
    this.storage.clearAll();
    /*if (!_.isEmpty(cmnSocketSvc.stompClient) && cmnSocketSvc.stompClient.connected) {
     cmnSocketSvc.disconnect();
     }*/
    // svc.clearAuthorizationHeader();
  };

  logout(): void {
    this.clearSessionData();
    this.socket.disconnect();
  };


  isAuthenticated(): boolean {
    return !_.isEmpty(this.storage.getUserGUID()) && !_.isEmpty(this.storage.getTokenData());
  };

  restorePassword(username: string): Observable<HttpResponse<object>> {
    return this.http.get(this.utils.getPublicServerUrl() + '/password/renew/' + username + '/',
      {observe: 'response'}
    );
  };

  resetPassword(password: string, token: string): Observable<HttpResponse<object>> {
    return this.http.put(this.utils.getPublicServerUrl() + '/validate/renewal/' + token + '/',
      password,
      {observe: 'response'}
    );
  };

  activate(token: string): Observable<object> {
    return this.http.get(this.utils.getPublicServerUrl() + '/token/' + token);
  };

  preactivate(token: string, password: string): Observable<object> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(this.utils.getPublicServerUrl() + '/tokenandpassword/' + token,
      password
      , {headers: headers});
  };

  refreshToken(forceRefresh?:boolean) {
    const token = this.storage.getMainUser();
    const expires = this.jwtHelper.getTokenExpirationDate(token.accessToken);
    if (expires > new Date() && !forceRefresh) {
      console.log('refresh')
      // observer.next(false);
      // observer.error('Not expired, something else is wrong');
      return Observable.throwError('Not expired, something else is wrong');
    } else {
      const auth = 'Basic ' + btoa(environment.authUsername + ':' + environment.authPassword);
      const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
        .set('authorization', auth);

      const params = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('refresh_token', token.refreshToken);

      return this.http.post(this.utils.getAuthUrl(), params.toString(), {headers: headers, observe: 'response'})
        .pipe(map((response) => {
          let tokenRawData, tokenData;

          if (!response || response.status !== 200) {
            return new Error('Something wrong with refresh token');
          }

          tokenRawData = response.body;
          this.storage.setTokenData(tokenRawData);
          this.storage.setUserData(tokenRawData);
          tokenData = this.storage.setMainUser(tokenRawData);
          this.account.loadUser(null, true);

          return tokenData.accessToken;
        }));
    }
    /*

        debugger;
        return new Observable(observer => {
          const token = this.storage.getTokenData();
          const expires = this.jwtHelper.getTokenExpirationDate(token.accessToken);
          // if (expires > Date.now()) {

        });*/
  }

  changeUser(user): Observable<boolean | object> {
    this.socket.disconnect();

    const token = this.storage.getMainUser();
    const auth = 'Basic ' + btoa(environment.authUsername + ':' + environment.authPassword);
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
      .set('authorization', auth);

    const params = new HttpParams({encoder: new CustomEncoder()})
      .set('grant_type', 'user_allowed')
      .set('access_token', token.accessToken)
      .set('user_id_allowed', user.id);

    return this.http.post(this.utils.getAuthUrl(), params.toString(), {headers: headers, observe: 'response'})
    .map((response: any) => {
      
      let tokenRawData, tokenData;
      if (response.body.kzz === '') {
        response.body.kzz = null;
      }

      if (!response || response.status !== 200) {
        return false;
      }

      this.storage.setUserGUID();
      tokenRawData = response.body;
      tokenData = this.storage.setTokenData(tokenRawData);
      this.storage.setUserData(tokenData);
      this.account.loadUser(null, true);

      this.socket.initSocket();

      return response.body;
    });
  }

  backToMain(){
    this.socket.disconnect();
    this.storage.setUserGUID();
    this.storage.setTokenData(this.storage.getMainUser());
    this.storage.setUserData(this.storage.getMainUser());
    this.account.loadUser(null, true);

    this.socket.initSocket();
  }

  changeManagedUserToFull(userData): Observable<object> {
    if (userData.kzz === '') {
      userData.kzz = null;
    }
    return this.http.post(this.utils.getPublicServerUrl() + '/changeManagedUserToFull/' + userData.id, userData);
  };
}
