import {NgModule} from '@angular/core';
import {RouterModule, Routes, Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {AuthGuard} from './shared-services/auth-guard.service';
import {NotFoundComponent} from './not-found/not-found.component';

import {Title} from '@angular/platform-browser';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

const routes: Routes = [
  // {path: '', redirectTo: 'account', pathMatch: 'full'},
  {
    path: '', // login?redirect&redirectParams
    component: LandingComponent,
    data: {
      title: 'Prijava'
    }
  },
  {
    path: 'account',
    loadChildren: 'app/modules/public/public.module#PublicModule',
  },
  {
    path: 'g',
    loadChildren: 'app/modules/general/general.module#GeneralModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reservations',
    loadChildren: 'app/modules/reservations/reservations.module#ReservationsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'prescriptions',
    loadChildren: 'app/modules/prescriptions/prescriptions.module#PrescriptionsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: 'app/modules/documents/documents.module#DocumentsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'referrals',
    loadChildren: 'app/modules/referrals/referrals.module#ReferralsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'sick-lists',
    loadChildren: 'app/modules/sick-list/sick-list.module#SickListModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reservation-create',
    loadChildren: 'app/modules/reservation-create/reservation-create.module#ReservationCreateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'referral-create',
    loadChildren: 'app/modules/referral-create/referral-create.module#ReferralCreateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'sick-list-create',
    loadChildren: 'app/modules/sick-list-create/sick-list-create.module#SickListCreateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'document-create',
    loadChildren: 'app/modules/document-create/document-create.module#DocumentCreateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: 'app/modules/messages/messages.module#MessagesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'messages-create',
    loadChildren: 'app/modules/messages-create/messages-create.module#MessagesCreateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: 'app/modules/help/help.module#HelpModule'
  },
  {
    path: 'gdpr',
    loadChildren: 'app/modules/gdpr/gdpr.module#GDPRModule'
  },
  {
    path: 'terms',
    loadChildren: 'app/modules/terms/terms.module#TERMSModule'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {
  constructor(private router: Router, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.router.events
      .filter((event: NavigationEnd) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route: ActivatedRoute) => route.outlet === 'primary')
      .mergeMap((route: ActivatedRoute) => route.data)
      .subscribe((event: { title: string }) => {
        if (event.title) {
          this.titleService.setTitle(event.title + ' | doZdravnika');
        } else {
          this.titleService.setTitle('doZdravnika');
        }

      });
  }
}
